<template>
  <div>
    <div>
      <b-row>
        <b-card class="bg-gradient-primary icon-banner m-2 w-100">
          <b-row>
            <b-col sm="auto" class="align-self-center">
              <img src="/assets/images/logo/yaplay_white_icon.png" />
            </b-col>
            <b-col class="align-self-center">
              <h1 class="text-white">Selecione as próximas consultas</h1>
              <h4>para iniciar a aplicação do jogo</h4>
              
            </b-col>
          </b-row>
        </b-card>
      </b-row>
    </div>
    <div class="mt-3">
      <h1>Próximas Consultas</h1>
          <div v-if="!swiperDataNexts.length"  class="py-3">
          <h4>Não há pacientes agendados para esse jogo.</h4>
        </div>
      <swiper
        v-if="swiperDataNexts.length"
        class="swiper-centered-slides p-1"
        :options="swiperOptions"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      >
        <!-- slides -->
        <swiper-slide v-for="data in swiperDataNexts" :key="data.id">
          <agenda-card :agenda="data" :toUrl="`/jogos/${gameId}/${data.patient.id}/${data.id}/Sessao`" />
        </swiper-slide>


        <!-- Add Arrows -->
        <div slot="button-next" class="swiper-button-next" />
        <div slot="button-prev" class="swiper-button-prev" />
      </swiper>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

import PacienteCard from '@/components/cards/PacienteCard.vue'
import AgendaCard from '@/components/cards/AgendaCard.vue'
import medicalAppointmentService from '@/services/medicalAppointmentService'
import { BCard, BCardBody, BAvatar, BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    Swiper,
    SwiperSlide,
    AgendaCard,
    BCard,
    BRow,
    BCol,
  },
  props: {
    gameId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    swiperDataRecents: [],
    swiperDataNexts: [],
    swiperOptions: {
      slidesPerView: 1,
      spaceBetween: 10,
      centeredSlides: false,
      breakpoints: {
        640: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        800: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    },
  }),
  methods: {
    async getNextsPatient() {
      this.swiperDataNexts = await medicalAppointmentService.getRecentsPatientWithGameId(this.gameId)
    },
  },
  mounted() {
    this.getNextsPatient()
  },
}
</script>
